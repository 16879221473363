import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import model from './bitsol.glb';

if (module.hot) { module.hot.accept(function () { location.reload(); }); }

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.01, 10000);
const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

const controls = new OrbitControls(camera, renderer.domElement);
controls.enableDamping = true;
controls.dampingFactor = 0.25;
controls.enableZoom = false;
controls.enablePan = false;
controls.enableRotate = false;

const starCount = 3000;
const geometry = new THREE.BufferGeometry();
const positions = new Float32Array(starCount * 3);

for (let i = 0; i < positions.length; i++) {
    positions[i] = (Math.random() - 0.5) * 2000;
}

geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

const vertexShader = `
    void main() {
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
        gl_PointSize = 1.0; // Size of the dots
        gl_Position = projectionMatrix * mvPosition;
    }
`;

const fragmentShader = `
    void main() {
        gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0); // White color
    }
`;

const material = new THREE.ShaderMaterial({
    vertexShader,
    fragmentShader
});

const stars = new THREE.Points(geometry, material);
scene.add(stars);

const vertexShader_universe = `
    void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

// Fragment Shader
const fragmentShader_universe = `
    uniform float time;
    uniform float seed;
    uniform vec2 resolution;

    // Simple hash function
    float hash(float n) { return fract(sin(n) * 437788.5453123 * seed); }

    // Basic noise function using sine
    float noise(vec2 st) {
        vec2 i = floor(st);
        vec2 f = fract(st);

        // Four corners in 2D of a tile
        float a = hash(i.x + i.y * 57.0);
        float b = hash(i.x + i.y * 57.0 + 1.0);
        float c = hash(i.x + i.y * 57.0 + 57.0);
        float d = hash(i.x + i.y * 57.0 + 58.0);

        // Smooth Interpolation

        // Cubic Hermite curve (same as SmoothStep)
        vec2 u = f*f*(3.0-2.0*f);
        // Mix 4 corners percentages
        return mix(a, b, u.x) +
                (c - a)* u.y * (1.0 - u.x) +
                (d - b) * u.x * u.y;
    }

    void main() {
        vec2 uv = gl_FragCoord.xy / resolution.xy;
        vec2 uvTimeShift = uv + vec2(time * 0.05, time * 0.02);
        
        float n = noise(uvTimeShift * 3.0);

        // Adjust the threshold and color mix for space effect
        if (n < 0.01) {
            gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0); // Space (black)
        } else {
            float threshold = smoothstep(0.1, 0.7, n);
            vec3 color = mix(vec3(0.0, 0.0, 0.0), mix(vec3(0.8, 0, 0.1), vec3(0.4, 0, 0.7), threshold), threshold);
            gl_FragColor = vec4(color, 0.35);
        }
    }
`;

// Shader Material
const material_universe = new THREE.ShaderMaterial({
    vertexShader: vertexShader_universe,
    fragmentShader: fragmentShader_universe,
    uniforms: {
        time: { value: 1.0 },
        seed: { value: Math.random() },
        resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) }
    }
});

const geometry_universe = new THREE.PlaneGeometry(5000, 5000);
const universe = new THREE.Mesh(geometry_universe, material_universe);
// set opacity to .5 to universe
universe.material.transparent = true;
universe.material.opacity = 0.15;
scene.add(universe);

const gltfLoader = new GLTFLoader();
gltfLoader.load(model, function (gltf) {
    console.log(gltf);
    scene.add(gltf.scene);
    // scale the model
    gltf.scene.scale.set(7, 7, 7);
    // rotate the model
    gltf.scene.rotation.y = -1.5;
    gltf.scene.position.z = 50;
    gltf.scene.position.x = 0;
    // start the of the model
    const mixer = new THREE.AnimationMixer(gltf.scene);
    gltf.animations.forEach((clip) => {
        // Step 4: Play the animation
        console.log(clip);
        mixer.clipAction(clip).play();
    });

    // Animation loop
    const clock = new THREE.Clock();
    function animate_logo() {
        requestAnimationFrame(animate_logo);

        // Update the animation mixer on each frame
        const delta = clock.getDelta();
        mixer.update(delta);

        renderer.render(scene, camera);
    }

    animate_logo();
}, undefined, function (error) {
    console.error(error);
});

camera.position.z = 500;
var gyroPresent = false;

function animate() {
    requestAnimationFrame(animate);
    controls.update();

    material_universe.uniforms.time.value = performance.now() / 1000;

    // get mouse position

    // if gyroscope is available on the device then rotate the camera based on the device orientation
    window.addEventListener('devicemotion', (event) => {
        if (event.rotationRate.alpha || event.rotationRate.beta || event.rotationRate.gamma){
            gyroPresent = true;
            camera.position.y = event.rotationRate.alpha * Math.PI / 180;
            camera.position.x = event.rotationRate.beta * Math.PI / 180;
            // universe.lookAt(camera.position);
        }
    });

    if (!gyroPresent) {
        window.addEventListener('mousemove', (event) => {
            const mouse = new THREE.Vector2();
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

            camera.position.x = mouse.x * 100;
            camera.position.y = mouse.y * 100;

            // rotate the plane to face the camera
            universe.lookAt(camera.position);
        });
    }




    renderer.render(scene, camera);
}

animate();